import React, { useEffect } from "react";
import "./notification.css";

const Notification = ({ message, notificationType, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 3000);

    return () => clearTimeout(timer);
  }, [onClose]);

  const notificationClass = `notification ${
    notificationType === "error" ? "error" : "success"
  }`;

  return <div className={notificationClass}>{message}</div>;
};

export default Notification;
